<template>
  <div>
    <v-list-item-avatar :size="avatarSize">
      <img :src="obtieneUrlThumbor" alt="Image" />
    </v-list-item-avatar>
  </div>
</template>

<script>
import Thumbor from 'thumbor'
export default {
  name: 'ThumborImage',
  props: {
    imageUrl: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
    smartCrop: {
      type: Boolean,
      default: true,
    },
    avatarSize: {
      type: Number,
      default: 30,
    },
    tipoImagen: {
      type: String,
      default: 'imagen',
    },
  },
  computed: {
    // obtieneUrl() {
    //   let url = 'https://appapi.rbsistemas.mx/avatars/no-image.png'
    //   console.log(this.imageUrl)
    //   if (this.imageUrl) {
    //     url = this.imageUrl.avatar
    //   }
    //   return url
    // },
    obtieneUrlThumbor() {
      let thumbor = new Thumbor(
        process.env.VUE_APP_THUMBOR_SECURITY_KEY,
        process.env.VUE_APP_THUMBOR_URL
      )
      var imagen = '/images/image-placeholder.png'
      // console.log(`1.- Tipo imagen ${this.tipoImagen}`)

      if (this.tipoImagen === 'foto') {
        imagen = '/images/photo-placeholder.png'
      }

      // console.log(`2.- Imagen URL ${this.imageUrl}`)
      if (this.imageUrl) {
        imagen = this.imageUrl
      }
      // console.log(`3.- Imagen ${imagen}`)
      let urlThumbor = thumbor
        .setImagePath(imagen)
        .resize(this.width, this.height)
        .smartCrop(this.smartCrop)
        .buildUrl()
      return urlThumbor
    },
  },
}
</script>
